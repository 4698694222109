export default function resetEventModalScrollPosition() {
    const eventModal = document.querySelector('.event-modal');

    if (import.meta.server || !eventModal) {
        return;
    }

    eventModal.scroll({
        top: 0,
        behavior: 'smooth'
    });
}
