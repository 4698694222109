import { defineStore } from 'pinia';

export const useMenuStore = defineStore('menu', () => {
    const isOpen = ref(false);
    const navBarElement = ref<HTMLElement|null>(null);
    const modalElement = ref<HTMLElement|null>(null);
    const logoElement = ref<HTMLElement|null>(null);

    const logoOpacity = ref<string|null>(null);
    const logoTransform = ref<string|null>(null);

    const updateLogo = () => {
        if (!modalElement.value || !logoElement.value) {
            return;
        }

        const offsetTop = modalElement.value.scrollTop;

        applyOpacity(logoElement.value, offsetTop);
        applyTransform(logoElement.value, offsetTop);
    };

    const close = () =>{
        if (!import.meta.server && isOpen.value) {
            document.body.removeAttribute('data-modal-open');
            document.body.removeAttribute('data-prevent-scrolling');
        }

        isOpen.value = false;
    };

    const open = () =>{
        isOpen.value = true;

        if (!import.meta.server) {
            document.body.setAttribute('data-modal-open', 'true');
            document.body.setAttribute('data-prevent-scrolling', 'true');
        }
    };

    const toggle = () => {
        isOpen.value = !isOpen.value;
        setTimeout(function() {
            if (!process.server) {
                navBarElement.value = document.querySelector('.nav-bar');
                logoElement.value = document.querySelector('.nav-bar .logo');
                modalElement.value = document.querySelector('.dn-modal.menu-overlay');
            }

            if (modalElement.value && logoElement.value && navBarElement.value) {
                if (isOpen.value) {
                    // Store the original styling when opening the menu
                    logoOpacity.value = logoElement.value.style.opacity;
                    logoTransform.value = logoElement.value.style.transform;

                    // Reset any styling on the nav bar
                    navBarElement.value.style.opacity = '1';
                    navBarElement.value.style.transform = 'none';

                    modalElement.value.addEventListener('scroll', updateLogo);

                    updateLogo();
                } else {
                    modalElement.value.removeEventListener('scroll', updateLogo);

                    // Reset styling to whatever it was while opening
                    logoElement.value.style.opacity = logoOpacity.value as string;
                    logoElement.value.style.transform = logoTransform.value as string;
                }
            }
        }, 100);
    };

    useRouter().afterEach(() => {
        close();
    });

    return {
        isOpen,

        open,
        close,
        toggle
    };
});