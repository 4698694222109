<template>
    <BaseForm @submit="submitEmail">
        <template #title>{{ title }}</template>

        <template #before>
            Voor welk e-mailadres wil je je wachtwoord opnieuw instellen?
        </template>

        <template #content>
            <FieldValidator
                v-slot="{ field, meta, errors }"
                name="email"
            >
                <BaseFormField :errors="errors">
                    <BaseInput
                        v-bind="field"
                        type="email"
                        placeholder="E-mail"
                        :validator="meta"
                    />
                </BaseFormField>
            </FieldValidator>

            <div
                v-if="!!errorMessage"
                class="form__error"
            >
                {{ errorMessage }}
            </div>
        </template>

        <template #actions>
            <BaseButton
                type="button"
                @click.prevent="emit('backToLogin')"
            >
                Terug naar inloggen
            </BaseButton>

            <BaseButton
                type="submit"
                class="form__submit button--secondary"
            >
                Verzenden
                <template #after>
                    <BaseIcon icon="arrow-right" />
                </template>
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import {
    STATUS,
    PASSWORD_RESET_TOO_MANY_ATTEMPTS,
} from '~/constants/errorMessages';
import { useEmailConfirmStore } from '~/store/emailConfirm';
import { useForm, Field as FieldValidator } from 'vee-validate';

defineProps<{
    title?: string;
}>();

const emit = defineEmits(['emailSent', 'backToLogin']);

const errorMessage = ref<string|null>(null);

const { handleSubmit } = useForm<{ email: string }>({
    validationSchema: {
        email: 'required|email'
    }
});

const submitEmail = handleSubmit(async(formData) => {
    errorMessage.value = null;

    await useLaravelApi('/v2.0/user/email-confirmation/resend', {
        method: 'post',
        body: {
            email: formData.email
        }
    })
        .catch((error) => {
            if (error.response.status === 429) {
                errorMessage.value = PASSWORD_RESET_TOO_MANY_ATTEMPTS;
            } else {
                errorMessage.value = STATUS['500'];
            }
        });

    if (errorMessage.value === null) {
        useEmailConfirmStore().setEmail(formData.email);

        emit('emailSent');
    }
});
</script>
