<template>
    <footer
        v-if="footerData"
        class="footer"
    >
        <TheLogo class="footer__logo" />

        <div class="footer__body">
            <h2 class="footer__title">
                {{ footerData.footerTitle }}
            </h2>

            <nav
                class="footer__links"
                @mouseleave="activeNavKey = null"
            >
                <ul>
                    <li
                        v-for="(link, index) in footerData.links"
                        :key="`footer-navigation-${index}`"
                    >
                        <NavLink
                            class="footer__link"
                            :type="mapType(link.entry.type)"
                            :url="mapURI(link.entry)"
                            :blank="isExternal(link.entry)"
                            data-ga-category-specific="navigation-footer"
                            data-ga-action="click-footerlink"
                            :data-ga-label="mapURI(link.entry)"
                        >
                            {{ link.entry.customText || link.entry.text }}
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    </footer>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';

const navigationStore = useNavigationStore();

const footerData = navigationStore.footer;

const { mapType, mapURI, isExternal } = useNavigation();
</script>

<style lang="less" src="./TheFooter.less"></style>
