<template>
    <BaseModal
        class="event-modal"
        transition-name="event-modal"
        v-bind="$attrs"
        @close="closeModal"
    >
        <template #close-button>
            <div class="modal__close">
                <BaseButton
                    class="modal__close-button"
                    @click="closeModal"
                >
                    <BaseIcon icon="cross" />
                </BaseButton>
            </div>
        </template>

        <template #default>
            <slot />
        </template>
    </BaseModal>
</template>

<script setup>
import { useEventsStore } from '~/store/events';

const emit = defineEmits(['close']);

const eventsStore = useEventsStore();

const closeModal = () => {
    document.querySelector('.event-modal').scroll({
        top: 0
    });

    navigateTo(eventsStore.startPath);

    emit('close');
};
</script>

<style lang="less" src="./EventModal.less" />
