<template>
    <header class="nav-bar">
        <div class="nav-bar__left">
            <NavLink
                v-if="!userStore.loggedIn"
                class="button dn-button button--secondary hidden-xxs hidden-xs hidden-sm hidden-md"
                :url="`/${SIGNUP_URL}`"
            >
                {{ becomeMemberText }}
            </NavLink>

            <BaseButton
                v-else-if="userStore.loggedIn"
                class="button--secondary hidden-xxs hidden-xs hidden-sm hidden-md"
                @click="modalStore.open({ name: MODAL_USER_CARD_SHEET })"
            >
                Mijn We Are Public-pas
            </BaseButton>

            <BaseButton
                v-if="!userStore.loggedIn"
                class="button--transparent hidden-xxs hidden-xs hidden-sm hidden-md"
                @click.prevent="modalStore.open({ name: MODAL_LOGIN })"
            >
                Inloggen
            </BaseButton>

            <NavLink
                v-else-if="userStore.loggedIn"
                class="button dn-button button--transparent hidden-xxs hidden-xs hidden-sm hidden-md"
                url="/profiel/overzicht"
            >
                Mijn profiel
            </NavLink>
        </div>

        <TheEscapeHatch class="nav-bar__home" />

        <div class="nav-bar__right">
            <TheSearchButton class="nav-bar__search" />

            <TheHamburgerMenu class="nav-bar__toggle" />
        </div>
    </header>
</template>

<script>
import { applyOpacity, applyTransform } from '~/utils/transforms';
import { SIGNUP_URL } from '~/constants/membership';
import { MODAL_LOGIN, MODAL_USER_CARD_SHEET, useModalStore } from '~/store/modal';
import { useUserStore } from '~/store/user';
import { useGlobalStore } from '~/store/global';

export default {
    data() {
        return {
            navBar: null,
            modalStore: useModalStore(),
            userStore: useUserStore(),
            MODAL_LOGIN,
            MODAL_USER_CARD_SHEET,
            SIGNUP_URL
        };
    },

    computed: {
        becomeMemberText() {
            return useGlobalStore().membershipTexts.becomeMemberCtaDefault;
        }
    },

    mounted() {
        this.navBar = document.querySelector('.nav-bar');
        window.addEventListener('scroll', this.updateHeader);
        this.updateHeader();
    },

    methods: {
        updateHeader() {
            const offsetTop = window.pageYOffset;
            applyOpacity(this.navBar, offsetTop);
            applyTransform(this.navBar, 0, offsetTop, 0.75);
        }
    }
};
</script>

<style lang="less" src="./TheNavBar.less"></style>
