<template>
    <NuxtLink
        to="/"
        aria-label="thuis knop"
        class="escape-hatch"
    >
        <TheLogo />
    </NuxtLink>
</template>

<script>
export default {

};
</script>

<style lang="less" src="./TheEscapeHatch.less"></style>
