<template>
    <div class="login">
        <FormLogin
            v-if="currentStep === STEP_LOGIN"
            title="Inloggen"
            @forgot-password="setStep(STEP_FORGOT_PASSWORD)"
        />

        <FormForgotPassword
            v-else-if="currentStep === STEP_FORGOT_PASSWORD"
            title="Wachtwoord vergeten"
            @back-to-login="setStep(STEP_LOGIN)"
            @email-sent="setStep(STEP_VERIFY)"
        />

        <FormVerifyCode
            v-else-if="currentStep === STEP_VERIFY"
            @next-step="setStep(STEP_NEW_PASSWORD)"
            @back-to-start="setStep(STEP_FORGOT_PASSWORD)"
        />

        <FormResetPassword
            v-else-if="currentStep === STEP_NEW_PASSWORD"
            @submit-password="submitPassword"
        />

        <BasePanel
            v-else-if="currentStep === STEP_PASSWORD_CHANGED"
        >
            <template #title>Je wachtwoord is opgeslagen</template>

            <p class="panel__notice">
                Vanaf nu kun je inloggen met je nieuwe wachtwoord.
            </p>

            <template #after>
                <BaseButton
                    class="button--secondary form__button--max-width"
                    @click="dismiss"
                >
                    Doorgaan
                </BaseButton>
            </template>
        </BasePanel>
    </div>
</template>

<script setup lang="ts">
import { MODAL_LOGIN, useModalStore } from '~/store/modal';

const STEP_LOGIN = 'login';
const STEP_FORGOT_PASSWORD = 'forgot-password';
const STEP_VERIFY = 'verify';
const STEP_NEW_PASSWORD = 'new-password';
const STEP_PASSWORD_CHANGED = 'password-changed';

const STEPS = [STEP_LOGIN, STEP_FORGOT_PASSWORD, STEP_VERIFY, STEP_NEW_PASSWORD, STEP_PASSWORD_CHANGED];

const modalStore = useModalStore();

const {
    currentStep,

    setStep,
} = useHasSteps(STEPS, STEP_LOGIN);

const submitPassword = () => {
    currentStep.value = STEP_PASSWORD_CHANGED;
};

const dismiss = async() => {
    modalStore.close({ name: MODAL_LOGIN });

    await navigateTo('/');
};
</script>

<style lang="less" src="./TheLogin.less" />
