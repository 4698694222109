<template>
    <div class="layout">
        <TheNavBar />

        <slot />

        <TheFooter />

        <Teleport to="body">
            <div
                id="transition-backdrop"
                data-visible="false"
            />

            <ClientOnly>
                <LazyTheToasts />

                <LazyTheMenuOverlay />

                <LazyCookieBar />

                <EventModal
                    :is-visible="isEventDetailOpen"
                    @close="closeEvent"
                >
                    <PageEvent
                        v-if="eventsStore.activeEventId"
                        :event-id="eventsStore.activeEventId"
                    />
                </EventModal>

                <Transition name="fade">
                    <LazyEventFilters
                        v-show="eventFiltersStore.filtersVisible"
                    />
                </Transition>
            </ClientOnly>

            <BaseSheet
                :is-visible="isLoginOpen"
                @close="modalStore.close({ name: MODAL_LOGIN })"
            >
                <TheLogin />
            </BaseSheet>

            <LazyEventReservationSheet />

            <LazyUserCardSheet />
        </Teleport>
    </div>
</template>

<script lang="ts" setup>
import { nextTick } from 'vue';
import { useEventsStore } from '~/store/events';
import { useEventFiltersStore } from '~/store/eventFilters';
import { MODAL_LOGIN, MODAL_MAIN, useModalStore } from '~/store/modal';
import { faviconHead } from '~/constants/favicons';
import resetEventModalScrollPosition from '~/helpers/resetEventModalScrollPosition';

/**
 * Stores
 */
const modalStore = useModalStore(),
    eventsStore = useEventsStore(),
    eventFiltersStore = useEventFiltersStore();

/**
 * Computed
 */
const isLoginOpen = computed(() => {
        return modalStore.activeModals[MODAL_LOGIN] === true;
    }),

    isEventDetailOpen = computed(() => {
        return modalStore.activeModals[MODAL_MAIN] === true;
    });

/**
 * Functions
 */
const closeEvent = () => {
    modalStore.close({ name: MODAL_MAIN });

    setTimeout(() => {
        eventsStore.deactivate();
    }, 500);
};

/**
 * Watchers
 */
watch(isEventDetailOpen, (value) => {
    if (value === true) {
        nextTick(resetEventModalScrollPosition);
    }
});

useHead(faviconHead);
</script>

<style lang="less">
body[data-prevent-scrolling='true'] {
    overflow-y: hidden;
}
</style>
