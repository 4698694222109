<template>
    <section
        class="hamburger-menu"
    >
        <BaseButton
            :class="{ 'open': menuStore.isOpen }"
            type="button"
            class="hamburger-menu__button button--transparent button--icon-only"
            aria-label="Open menu"
            @click="menuStore.toggle"
        >
            <i class="dn-icon hamburger-menu__icon">
                <span />
                <span />
                <span />
                <span />
            </i>
        </BaseButton>
    </section>
</template>

<script setup>
import { useMenuStore } from '~/store/menu';

const menuStore = useMenuStore();
</script>

<style lang="less" src="./TheHamburgerMenu.less"></style>
