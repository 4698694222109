<template>
    <NuxtLink
        :to="link"
        aria-label="Zoeken"
        class="button dn-button search-button button--transparent button--icon-only"
    >
        <BaseIcon :icon="icon" />
    </NuxtLink>
</template>

<script setup>
import { useSearchStore } from '~/store/search';

const searchStore = useSearchStore();

const route = useRoute();

const isSearchPage = computed(() => {
    return route.name === 'zoeken';
});

const link = computed(() => {
    if (isSearchPage.value) {
        return searchStore.previousRoute ?? '/';
    }

    return '/zoeken';
});

const icon = computed(() => {
    if (isSearchPage.value) {
        return 'arrow-left';
    }
    return 'search';
});
</script>

<style lang="less" src="./TheSearchButton.less" />
