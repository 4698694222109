<template>
    <figure class="logo">
        <img
            alt="We Are Public logo"
            width="85"
            height="45"
            :src="logoImage"
            class="logo__image"
        >
    </figure>
</template>

<script setup lang="ts">
import logoImage from '~/assets/img/logos/logo.svg';
</script>

<style lang="less" src="./TheLogo.less"></style>
